import React, {useState} from 'react'
import {Text, TextButtonPriority, TextButton} from 'wix-ui-tpa/cssVars'
import {ChevronUp, ChevronDown} from 'wix-ui-icons-common'
import classNames from 'classnames'
import {scheduleDataHooks as DH} from '@wix/wix-events-data-hooks'
import xss from 'xss'
import settingsParams from '../../../../settingsParams'
import {useWidgetProps} from '../../../hooks/widget-props'
import {useColors} from '../../../hooks/colors'
import {useModal} from '../../../hooks/modal'
import {useSettings} from '../../../../hooks/settings'
import {classes as c} from './description.st.css'
import {DescriptionProps} from '.'

export const Description = ({text, itemId}: DescriptionProps) => {
  const {insideModal} = useModal()
  const {readDescriptionClicked} = useWidgetProps()
  const [opened, toggle] = useState(false)
  const settings = useSettings()
  const {isDarkLinkColor} = useColors()
  const readMoreText = settings.get(settingsParams.readMore)
  const readLessText = settings.get(settingsParams.readLess)

  const wired = isDarkLinkColor()

  const handleReadMore = () => {
    const newOpened = !opened

    if (newOpened) {
      readDescriptionClicked({itemId})
    }

    toggle(newOpened)
  }

  return (
    <div className={c.container}>
      <TextButton
        className={classNames(c.button, {
          [c.blackButton]: insideModal && !wired,
          [c.defaultButton]: !insideModal || wired,
        })}
        priority={TextButtonPriority.primary}
        data-hook={DH.DESCRIPTION_BUTTON}
        onClick={handleReadMore}
        suffixIcon={opened ? <ChevronUp /> : <ChevronDown />}
        aria-expanded={opened}
      >
        {opened ? readLessText : readMoreText}
      </TextButton>
      {opened && (
        <Text
          className={classNames(c.text, {[c.defaultText]: !insideModal, [c.blackText]: insideModal})}
          data-hook={DH.DESCRIPTION}
        >
          <div dangerouslySetInnerHTML={{__html: xss(text)}} />
        </Text>
      )}
    </div>
  )
}
