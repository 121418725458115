import classNames from 'classnames'
import React from 'react'
import Location from 'wix-ui-icons-common/LocationSmall'
import {scheduleDataHooks as DH} from '@wix/wix-events-data-hooks'
import {Tags} from '../../filters/tags'
import {Description} from '../description'
import {ItemDivider} from '../divider'
import {Time} from '../time'
import {
  getScheduleItemDescription,
  isScheduleItemCanceled,
  isScheduleItemWithIndication,
} from '../../../selectors/schedule'
import {Indications} from '../../indications'
import {useWidgetProps} from '../../../hooks/widget-props'
import {useModal} from '../../../hooks/modal'
import {useVisibilityStyles} from '../../../../hooks/use-visibility-styles'
import s from './desktop.scss'
import {Bookmark} from './bookmark'
import type {ItemProps} from '.'

export const DesktopItem = ({item, showDivider}: ItemProps) => {
  const {insideModal} = useModal()
  const {t} = useWidgetProps()
  const {formattedTimeSlot, formattedDuration, name, location, tags, id, formattedUpdatedDate, bookmarked} = item
  const {
    allBreakpoints: {isDurationVisible},
  } = useVisibilityStyles()
  const description = getScheduleItemDescription(item)
  const canceled = isScheduleItemCanceled(item)

  return (
    <li className={classNames({[s.insideModal]: insideModal})}>
      <Indications canceled={canceled} t={t} formattedUpdatedDate={formattedUpdatedDate} />
      <div
        className={classNames(s.itemContent, {
          [s.itemWithIndication]: isScheduleItemWithIndication(item),
        })}
        data-hook={DH.SCHEDULE_ITEM}
      >
        <div className={s.timeContainer}>
          <Time className={s.timeSlot} data-hook={DH.SCHEDULE_ITEM_DATETIME} dateTime={formattedTimeSlot}>
            {formattedTimeSlot}
          </Time>
          {isDurationVisible() ? (
            <div className={s.duration} data-hook={DH.SCHEDULE_ITEM_DURATION}>
              {formattedDuration}
            </div>
          ) : null}
        </div>
        <div className={s.content}>
          {insideModal ? (
            <h4 className={classNames(s.title, s.text)} data-hook={DH.SCHEDULE_ITEM_NAME}>
              {name}
            </h4>
          ) : (
            <h3 className={classNames(s.title, s.text)} data-hook={DH.SCHEDULE_ITEM_NAME}>
              {name}
            </h3>
          )}
          {location && (
            <div className={s.location} data-hook={DH.LOCATION}>
              <div>
                <Location data-hook={DH.LOCATION_ICON} aria-label={t('aria.scheduleItem.location')} role="img" />
              </div>
              <div className={s.text}>{location}</div>
            </div>
          )}
          {tags && <Tags t={t} className={s.tags} tags={tags} />}
          {description && <Description text={description} itemId={id} />}
        </div>
        <Bookmark itemId={id} bookmarked={bookmarked} />
      </div>
      {showDivider && <ItemDivider />}
    </li>
  )
}
