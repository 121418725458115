import {createAction} from '@reduxjs/toolkit'
import {CreateBookmarkResponse, DeleteBookmarkResponse} from '@wix/ambassador-wix-events-agenda/types'
import {EventStatus} from '@wix/ambassador-wix-events-web/types'
import {draftEventPreviewUrlQueryParamName} from '@wix/wix-events-commons-statics'
import {SchedulePageData, ExtendedListScheduleItemsResponse} from '@wix/events-types'
import {getLanguage, getLocale, getTimezone} from '../../../../commons/utils/wix-code-api'
import {isUserLoggedIn} from '../selectors/member'
import {getSlug} from '../services/location'
import {createAsyncAction} from '../services/redux-toolkit'
import {promptLogin} from './member'
import {togglePersonalAgendaModal} from './modals'

export const getSchedule = createAsyncAction<ExtendedListScheduleItemsResponse>(
  'GET_SCHEDULE',
  (_, {extra, getState}) => {
    const state = getState()
    const eventId = state.event.id
    const draft = state.event.status === EventStatus.DRAFT
    const {location, tags} = state.filter
    const locale = getLocale(extra.controllerParams.controllerConfig.wixCodeApi)

    return extra.api.getSchedule(eventId, location, tags, locale, draft)
  },
)

export const getScheduleData = createAsyncAction<SchedulePageData>('GET_SCHEDULE_DATA', (_, {extra}) => {
  const {wixCodeApi} = extra.controllerParams.controllerConfig

  const locale = getLocale(wixCodeApi)
  const language = getLanguage(wixCodeApi)
  const slug = getSlug(extra.controllerParams)
  const {query} = wixCodeApi.location
  const draftPreviewToken = query[draftEventPreviewUrlQueryParamName]
  const timeZoneId = getTimezone(wixCodeApi)
  const {isEditor} = extra.controllerParams.flowAPI.environment

  return extra.api.getScheduleData({slug, language, locale, draftPreviewToken, timeZoneId, isEditor})
})

export const getBookmarks = createAsyncAction('GET_BOOKMARKS', (_, {extra: {api}, getState}) =>
  api.getBookmarks(getState().event.id),
)

export const createBookmark = createAsyncAction<CreateBookmarkResponse, HandleBookmarkArgs>(
  'CREATE_BOOKMARK',
  ({itemId}, {extra: {api}, getState}) => api.createBookmark(getState().event.id, itemId),
  {
    condition: (_, {getState}) => {
      const state = getState()
      return !state.schedule.bookmarking
    },
  },
)

export const deleteBookmark = createAsyncAction<DeleteBookmarkResponse, HandleBookmarkArgs>(
  'DELETE_BOOKMARK',
  ({itemId}, {extra: {api}, getState}) => api.deleteBookmark(getState().event.id, itemId),
  {
    condition: (_, {getState}) => {
      const state = getState()
      return !state.schedule.bookmarking
    },
  },
)

export const readDescriptionClicked = createAction<{itemId: string}>('READ_DESCRIPTION_CLICKED')

export const viewPersonalAgenda = createAsyncAction<void, {origin: PersonalAgendaOrigin}>(
  'VIEW_PERSONAL_AGENDA',
  async (_, {getState, dispatch}) => {
    const loggedIn = isUserLoggedIn(getState().member)

    if (loggedIn) {
      dispatch(togglePersonalAgendaModal())
    } else {
      dispatch(promptLogin())
    }
  },
)

export type ReadDescriptionClicked = typeof readDescriptionClicked
export type CreateBookmark = typeof createBookmark
export type DeleteBookmark = typeof deleteBookmark
export type ViewPersonalAgenda = typeof viewPersonalAgenda

interface HandleBookmarkArgs {
  itemId: string
  origin: BookmarkOrigin
}

export enum BookmarkOrigin {
  Schedule = 'schedule',
  ItemDetails = 'item details',
  MyAgenda = 'my agenda',
}

export enum PersonalAgendaOrigin {
  Schedule = 'schedule',
  ItemDetails = 'item details',
}
